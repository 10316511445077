<template>
  <v-main style="background-color:#efefef;">
    <v-container >
        <v-card
            class="bl_contactCard"
            elevation="5"
            max-width="600"
            style="margin-top:50px; margin-bottom:50px; padding: 32px 16px;"
        >
            <v-card-title>ページがみつかりませんでした</v-card-title>
            <v-card-text>
                <router-link to="/" style="color:black;"
                >お手数ですが、この文章をクリックしてページの最初からご覧ください。</router-link>
            </v-card-text>
        </v-card>
    </v-container>
</v-main>
</template>

<script>

  export default {
    name: 'notfound',
    components: {

    },
    methods:{
    },
    data(){
      return{
      }
    }
  }
</script>

<style scoped>
</style>
